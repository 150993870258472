import { fadeInDownStyle, fadeInUpStyle ,pageStyles } from "../styles/style";
import Footer from "../components/Footer";
import Downbar from "../components/down_footer_pulizie";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../styles/main.css";
import axios from "axios";
import Swal from "sweetalert2";
import FloatButton from "../components/FloatButton";

// manutenpul styles
// data
// cards services
const validElement = (input) => {
  console.log(input.value);
};

const handleSubmit = event =>{
  event.preventDefault();
  console.log(event.target);
  event.target.querySelectorAll('input,select,textarea').forEach(function (value, index) {
    validElement(value);
  });
  let formData = new FormData(event.target);
  axios.post(
    'https://erp.manutenpul.it/contact/me', 
    formData,
    {
      headers: {
        // "X-Requested-With": "XMLHttpRequest",
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data;",
      },
      responseType: 'json',
    }).then((response)=>{
      Swal.fire(
        'Grazie!',
        'Ti risponderemo il prima possibile',
        'success'
      ).then((success)=>{
        window.location.reload();
      });
    }).catch((error)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'compilando il form',
      });
    });
};

// markup
const ContactPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>MANUTENPUL | Impresa di Pulizie | Contatti</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar />
      <Downbar></Downbar>
      <Banner pageName="Contatti" />
      <section className="contact-section pt-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 order-2">
              <br/>
              <div className="contact-item-wrapper">
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <span className="material-icons">call</span>
                      </div>
                      <div className="contact-content">
                        <h4>Contatti</h4>
                        <p><a href="tel:+39.02.31055156">+39.02.31055156</a></p>
                        <p><a href="tel:+39.3917415330">+39 3917415330</a></p>
                        <p><a href="mailto:info@manutenpul.it">info@manutenpul.it</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <span className="material-icons">location_on</span>
                      </div>
                      <div className="contact-content">
                        <h4>Sede Legale:</h4>
                        <p>Viale pisa 6</p>
                        <p>Milano, MI 20146</p>
                        <p>-----</p>
                        <h4>Sede Operativa:</h4>
                        <p>Via Raffaello Sanzio 18</p>
                        <p>Cesano Boscone, MI 20090</p>
                        <p>-----</p>
                        <h4>Sede Operativa:</h4>
                        <p>Via XX Settembre 137</p>
                        <p>Legnano, MI 20025</p>
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <span className="material-icons">schedule</span>
                      </div>
                      <div className="contact-content">
                        <h4>Orario</h4>
                        <p>Lunedi - Sabato</p>
                        <p>08:30 - 13:00 , 14:00 - 18:30</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 order-1">
              <div className="contact-form-wrapper">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 mx-auto">
                    <div className="section-title text-center mb-50">
                      <span
                        className="wow fadeInDown"
                        data-wow-delay=".2s"
                        style={fadeInDownStyle}
                      >
                        Richiedi il Tuo Preventivo Gratuito!
                      </span>
                      {/* <h2
                        className="wow fadeInUp"
                        data-wow-delay=".4s"
                        style={fadeInUpStyle}
                      >
                        Richiedi il tuo preventivo GRATUITO
                      </h2> */}
                      <p
                        className="wow fadeInUp"
                        data-wow-delay=".6s"
                        style={fadeInUpStyle}
                      >
                        Compilado il seguente Form:
                      </p>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit} className="contact-form">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="Azienda">Azienda/Società:</label>
                      <input
                        type="text"
                        name="Azienda"
                        id="Azienda"
                        placeholder="Ragione Sociale"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="Nome_Cognome">Contatto Sig:</label>
                      <input
                        type="text"
                        name="Nome_Cognome"
                        id="Nome_Cognome"
                        placeholder="Nome Cognome"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="Indirizzo">Indirizzo:</label>
                      <input
                        type="text"
                        name="Indirizzo"
                        id="Indirizzo"
                        placeholder="Indirizzo completo"
                        
                      />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="Citta">Città:</label>
                      <input
                        type="text"
                        name="Citta"
                        id="Citta"
                        placeholder="Inserisci.."
                        
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="Telefono_cel">Telf. / Cell.:</label>
                      <input
                        type="text"
                        name="Telefono_cel"
                        id="Telefono_cel"
                        placeholder="Inserisci.."
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="Email">Email:</label>
                      <input
                        type="email"
                        name="Email"
                        id="Email"
                        placeholder="Indirizzo Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="id_tip_serv">Tipo di Servizio</label>
                        <select name="tip_serv" className="form-control" autoComplete="off" id="id_tip_serv" required>
                            <option value="Seleziona">Seleziona</option>
                            <option value="Pulizia Ord.">Pulizia Ordinaria</option>
                            <option value="Pulizia StraOrd.">Pulizia StraOrdinaria</option>
                            <option value="Sanificazione">Sanificazione</option>
                            <option value="Trattamento Pavimenti">Trattamento Pavimenti</option>
                            <option value="Rotazione Sacchi">Rotazione Sacchi</option>
                            <option value="Disinfestazioni">Disinfestazione</option>
                            <option value="Giardinaggio">Giardinaggio</option>
                            <option value="Facchinaggio">Facchinaggio</option>
                            <option value="Sgombero">Sgombero</option>
                            <option value="Lavaggio vetri">Lavaggio vetri</option>
                            <option value="Ristrutturazioni">Ristrutturazioni</option>
                            <option value="Imbiancatura/Verniciatura">Imbiancatura/Verniciatura</option>
                            <option value="Altro">Altro</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="Mq">Metri quadri:</label>
                      <input
                        type="number"
                        name="Mq"
                        id="Mq"
                        placeholder="mq."
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="id_tip_local">Tipologia locale</label>
                        <select name="tip_local" className="form-control" autoComplete="off" id="id_tip_local">
                          <option value="Seleziona">Seleziona</option>
                          <option value="Ufficio">Ufficio</option>
                          <option value="Negozio">Negozio</option>
                          <option value="Reparto Industriale">Reparto Industriale</option>
                          <option value="Studio Medico">Studio Medico</option>
                          <option value="Condominio">Condominio</option>
                          <option value="Appartamento">Appartamento</option>
                          <option value="Palestra">Palestra</option>
                          <option value="Palestra">Altro</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="id_image1">Foto 1</label>
                        <input type="file" name="image1" accept="image/*" className="form-control" autoComplete="off" id="id_image1"/>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                        <label htmlFor="id_image2">Foto 2</label>
                        <input type="file" name="image2" accept="image/*" className="form-control" autoComplete="off" id="id_image2"/>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="id_image3">Foto 3</label>
                        <input type="file" name="image3" accept="image/*" className="form-control" autoComplete="off" id="id_image3"/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="desc">Descrivi in modo preciso la tua richiesta:</label>
                      <textarea
                        name="desc"
                        id="desc"
                        placeholder="Es. : La pulizia degli uffici"
                        rows="5"
                        maxLength="500"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="button text-center">
                        <button type="submit" className="theme-btn">
                          Invia Richiesta
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            
          </div>
          <br/>
          <div>
            <iframe title="MiniMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.164567278477!2d9.101485715556848!3d45.44633977910069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c1802454991d%3A0x4d81eb1d875f60fd!2sMANUTENPUL%20-%20Impresa%20di%20Pulizie%20E%20Servizi!5e0!3m2!1ses-419!2smx!4v1634800190259!5m2!1ses-419!2smx" width="100%" height="450" loading="lazy"></iframe>
          </div>
          <br/>
        </div>
      </section>
      
      <Footer></Footer>
    </main>
  );
};

export default ContactPage;
